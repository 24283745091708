<template>
  <div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mb-4">
      <div class="card-body px-lg-5 py-lg-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-control-label">Title</label>
            <span class="ml-1 text-danger">*</span>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the title"
              v-model="data.title"
            />
            <label
              v-for="error in errors.title"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
                <div class="row">
          <div class="form-group col-md-6">
            <label class="form-control-label">link label</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the link label"
              v-model="data.link_label"
            />
            <label
              v-for="error in errors.link_label"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>

        </div>
        <div class="row">
          <div class="col-md-10">
            <label class="form-control-label">Content </label>
            <span class="ml-1 text-danger">*</span>
            <rich-text id="content" @change="handle_content" />
            <label
              v-for="error in errors.content"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-secondary px-5" @click="this.$router.go(-1)">
              Cancel
            </button>
            <button
              class="btn btn-md btn-default my-4"
              @click="create_resource"
            >
              Add Resource
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RichText from "../../components/RichTextEditor.vue";
import axios from "axios";
import { alert } from "../../utils/alertUtils";

export default {
  name: "NewResource",
  components: {
    "rich-text": RichText,
  },
  data() {
    return {
      data: {},
      errors: {
        description: [],
      },
    };
  },
  methods: {
    create_resource: function () {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources`;
      axios
        .post(url, this.data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          alert.success_center("Resource added successfully.");
          this.$router.push({
            name: "ResourceUploadImage",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.status === 400) {
            this.errors = err.response.data;
            alert.error("An error occured, check the form for specific erros!");
            this.errors = err.response.data;
          } else {
            alert.error(
              "An error occured please try again,if the error persist kindly contact the owner of this site!"
            );
          }
        });
    },
    handle_content: function (content) {
      this.data.content = content;
    },
  },
};
</script>